import { login } from "@/api/auth"
import { getEnv } from "./utils/env"

const { VUE_APP_WECHAT_COMPANY_AUTH_URL, VUE_APP_WECHAT_COMPANY_APPID, VUE_APP_WECHAT_COMPANY_AGENTID } = getEnv()
const redirectUri = encodeURIComponent(window.location.origin)
const appId = VUE_APP_WECHAT_COMPANY_APPID
const agentid = VUE_APP_WECHAT_COMPANY_AGENTID
const authBaseUrl = VUE_APP_WECHAT_COMPANY_AUTH_URL
const authURL = `${authBaseUrl}/connect/oauth2/authorize?appid=${appId}&agentid=${agentid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_privateinfo#wechat_redirect`
/** 认证，去获取code */
export const toAuthDirectly = () => window.location.href = authURL

export function auth(cb) {
  if (getToken()) return
  const us = new URLSearchParams(window.location.search)
  const code = us.get('code')
  if (code) {
    login({
      code
    }).then((res) => {
      const { data, code } = res.data
      if (code === 0) {
        setToken(data)
        cb && cb()
      }
    })
  } else {
    toAuthDirectly()
  }
}

const tokenKey = 'token'

export function getToken() {
  return sessionStorage.getItem(tokenKey)
}

export function setToken(token) {
  sessionStorage.setItem(tokenKey, token)
}

export function removeToken() {
  sessionStorage.removeItem(tokenKey)
}
