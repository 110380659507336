import axios from "axios"
import { Toast } from "vant"
import { getToken, removeToken, toAuthDirectly } from "@/auth"
import { getEnv } from "./env"

const { VUE_APP_BASE_URL } = getEnv()

const service = axios.create({
  VUE_APP_BASE_URL
})

service.interceptors.request.use(
  (config) => {
    try {
      const token = getToken()
      if (token) {
        config.headers["Authorization"] = `bearer ${token}`
      }
    } catch (e) {
      console.log(e)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      const msg = response.message || "Error"
      Toast({
        type: 'text',
        message: msg,
        duration: 2000,
      })
      return Promise.reject(new Error(msg))
    } else {
      const { code, message } = response.data
      if (code !== 0) {
        Toast({ type: 'text', message, duration: 2000 })
      }
      return response
    }
  },
  (error) => {
    // eslint-disable-next-line no-debugger
    let message
    
    if (error.response && error.response.status === 401) {
      removeToken()
      toAuthDirectly()
      return
    }
    if (error && error.response) {
      message = error.response.data.message
    }
    message = message || "连接服务器失败"
    Toast({ type: 'text', message, duration: 2000 })
    return Promise.reject(error)
  }
)

export default service
