import request from '@/utils/request'

/** 排队解锁 */
export function queue() {
  return request({
    url: '/api/unlock',
    method: 'post'
  })
}

/** 获取排队信息 */
export function getQueueInfo() {
  return request({
    url: '/api/unlock/getCurrentInfo',
    method: 'get',
  })
}
