import Vue from 'vue'
import { Button, Loading, Dialog } from 'vant'
import 'vant/lib/button/style'
import 'vant/lib/toast/style'
import 'vant/lib/dialog/style'
import 'vant/lib/loading/style'
import App from './App.vue'
import { isDev } from './utils/env'
import './styles/vant.css'

Vue.use(Button)
  .use(Loading)
  .use(Dialog)

Vue.config.productionTip = false
Vue.config.devtools = isDev

new Vue({
  render: h => h(App),
}).$mount('#app')
