export function getEnv() {
  const {
    port,
    NODE_ENV,
    VUE_APP_BASE_URL,
    VUE_APP_REDIRECT_LOCAL_HOST,
    VUE_APP_WECHAT_COMPANY_AUTH_URL,
    VUE_APP_WECHAT_COMPANY_APPID,
    VUE_APP_WECHAT_COMPANY_AGENTID
  } = process.env
  return {
    port,
    NODE_ENV,
    VUE_APP_BASE_URL,
    VUE_APP_REDIRECT_LOCAL_HOST,
    VUE_APP_WECHAT_COMPANY_AUTH_URL,
    VUE_APP_WECHAT_COMPANY_APPID,
    VUE_APP_WECHAT_COMPANY_AGENTID
  }
}

const { NODE_ENV } = getEnv()

export const isDev = NODE_ENV === 'development'